import PaymentForm from "../../components/payment-form/payment-form.component";

import { CheckoutContainer, CheckoutRow, CheckoutCol, DescriptionContainer, FormContainer } from "./checkout.styles";

const CheckoutPage = () => {
    return(
        <CheckoutContainer>
            <CheckoutRow>
                <CheckoutCol lg={6}>
                    <DescriptionContainer>
                        <h2>How to Submit a Payment</h2>
                        <p>
                        Here is a step-by-step guide on how to submit a payment:
                        <ol>
                            <li>Step 1: Fill in your payment details.</li>
                            <li>Step 2: Review the payment information.</li>
                            <li>Step 3: Click the "Submit Payment" button.</li>
                        </ol>
                        </p>
                    </DescriptionContainer>
                </CheckoutCol>
                <CheckoutCol lg={6}>
                    <FormContainer>
                        <PaymentForm/>
                    </FormContainer>
                </CheckoutCol>
            </CheckoutRow>
        </CheckoutContainer>
    )
}

export default CheckoutPage;