import { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { postAPI } from '../../../../utils/api/api';

const defaultFormFields = {
  name: '',
  breed: '',
  location: '',
  email: '',
  message: "",
  token: ''
};

const NewClientForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [submitStatus, setSubmitStatus] = useState("none")
  const { name, breed, location, email, message } = formFields;
  const captchaRef = useRef(null)

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const clearFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const verify = () =>{
    if (captchaRef && captchaRef.current){
        let token = captchaRef.current.getValue()
        if (token!="" && token != null)
            setFormFields({...formFields, token})
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus("loading")
    try{
        if (captchaRef && captchaRef.current){
          console.log(formFields)
            const res = await postAPI("/contact/form", formFields)
            clearFormFields();
            captchaRef.current.reset();
            setSubmitStatus("true")
        }
        else{
            window.alert("ReCaptcha is Required")
        }            
    }
    catch(err){
        console.log(err)
        setSubmitStatus("false")
    }
}


  const submitFeedback = () => {
    if (submitStatus == "false"){
        return(
            <Button variant="primary" type="submit">
                <span style={{color:"red", fontWeight:"900"}}>  &#10008; </span>
            </Button>
        )
    }
    else if(submitStatus == "true"){
        return(
        <Button variant="primary" type="submit" disabled>
            <span style={{color:"green", fontWeight:"900"}}>  &#10004; </span>
        </Button>
    )
    }
    else if(submitStatus == "loading"){
        return(
            <Button variant="primary" type="submit" disabled>
                <Spinner animation="border" />
            </Button>
        )
    }
    return(
        <Button type="submit" className='custom-button-primary' size='lg'>
            Get In Touch
        </Button>
    )
}
  return (
    <Col md="10" lg="5" xl="5" className="aos-init aos-animate">
        <Card bg="opaque-black">
          <Card body bg="white">
            <Form className="row g-2" onSubmit={handleSubmit}>

              <FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
                <Form.Control type="text" placeholder="name" name='name' onChange={handleChange} value={name} required/>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Dog's Breed" className="mb-3">
                <Form.Control type="text" placeholder="breed" name='breed' onChange={handleChange} value={breed} required/>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Your Location (CO City)" className="mb-3">
                <Form.Control type="text" placeholder="location" name='location' onChange={handleChange} value={location} required/>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Email" className="mb-3">
                <Form.Control type="email" placeholder="email" name='email' onChange={handleChange} value={email} required/>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Message" className="mb-3">
                <Form.Control as="textarea" placeholder="message" name='message' onChange={handleChange} value={message} style={{ height: '150px' }} required/>
              </FloatingLabel>
            
            <ReCAPTCHA sitekey={"6LdwVzMoAAAAAMR2fuYwL2d2QiXDBLFnxdTu27uO"} ref={captchaRef} onChange={verify}/>

            <br/>

            {submitFeedback()}

          </Form>
        </Card>
      </Card>
    </Col>
  );
};

export default NewClientForm;
