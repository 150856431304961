import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styled from "styled-components";

export const ServiceContainer = styled.section`

    @media only screen and (min-width: 992px){
        padding-top: 100px;
    }

    .titlecontainer{
        padding-top: 2rem;
        padding-bottom: 8rem;
        height: 5rem;
        background-color: #7D828E;
        text-align: center;
        color: #1f2b40;

        p{
            color: white;
        }
    }

    
`

export const ServiceRow = styled(Row)`

`

export const ServiceCol = styled(Col)`

`

export const DescriptionSection= styled.section`
    padding:20px;
    text-align: center;
`

export const ParagraphContainer = styled.div`
    max-width: 500px;
    display: inline-block;
    position: relative;
    
    p{
        text-align: left;
    }
`