import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import DraggableImage from '../draggable-image/draggable-image.component';

const ImageUploadWithObjectPosition = ({
  setFormData,
  formData,
}) => {
  const [selectedFile, setSelectedFile] = useState(formData.profilePicture);
  const [objectPosition, setObjectPosition] = useState(
    formData.profilePicturePosition
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
    }
  };

  const handleDrag = (x, y) => {
    setObjectPosition({ x, y });
    setFormData({ ...formData, profilePicturePosition: { x, y } });
  };

  const handleButtonClick = () => {
    // Trigger the file input when the button is clicked
    document.getElementById('imageUpload').click();
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} md={6} className="text-center">
          {selectedFile && (
            <div>
              <DraggableImage
                src={selectedFile}
                objectPosition={objectPosition}
                onDrag={handleDrag}
              />
            </div>
          )}
          {
            formData.profilePicture || selectedFile ?
              <Button variant="primary" onClick={handleButtonClick} style={{fontSize: "0.85rem"}}>
              Change Picture
              </Button> 
              : 
              <Button variant="primary" onClick={handleButtonClick} style={{fontSize: "0.85rem"}}>
              Choose Picture
              </Button>
          }
          
          {/* Hidden file input */}
          <input
            type="file"
            name="profilePicture"
            id="imageUpload"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              handleFileChange(e);
              setFormData({
                ...formData,
                profilePicture: e.target.files[0],
                dogPicturePath: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ImageUploadWithObjectPosition;
