import styled from "styled-components";

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export const ServicesContainer = styled.section`
    // padding: 120px 20px;
    @media only screen and (min-width: 992px){
        padding-top: 100px;
    }
`;

export const ServicesCol = styled.section`

`;

export const ServicesRow = styled.section`

`;