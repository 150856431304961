const servicesMap = {
    "privateclass" : {
        name: "Private Class",
        catchphrase: "Are for those that are looking to work on a specific traning need or behavior",
        prices : [
            {
                name: "Private Session",
                price: 150,
                description: "Personalized individual sessions conducted at the preferred location of the customer's choice.",
                url: "https://buy.stripe.com/test_28o02m2om0P3d0c3cd"
            },
            {
                name: "Pack of 7 Home Private Session",
                price: 1000,
                description: "Personalized individual sessions conducted at the preferred location of the customer's choice.",
                url: ""
            },
            {
                name: "Bennett Private Session",
                price: 110,
                description: "Personalized individual sessions conducted at the trainer facility.",
                url: ""
            },
            {
                name: "Pack of 7 Bennett Session",
                price: 720,
                description: "Personalized individual sessions conducted at the trainer facility.",
                url: ""
            }
        ]
        
    },
    "groupclass" : {
        name: "Group Class",
        catchphrase: "Are for those that are looking to work in a group environment",
        prices : [
            {
                name: "1 Group Class",
                price: 75,
                description: "Canine training group sessions held at a trainer's facility situated in Bennett.",
                url: ""
            },
            {
                name: "Pack of 6 Group Classes",
                price: 300,
                description: "Canine training group sessions held at a trainer's facility situated in Bennett.",
                url: ""
            }
        ]
        
    },
    "boardandtraining" : {
        name: "Board and Training Program",
        catchphrase: "Are for those that who don't have the time to train",
        prices : [
            {
                name: "Basic 3 Week",
                price: 3400,
                description: "A comprehensive three-week boarding and training program that includes of two individual sessions and three group classes.",
                url: ""
            },
            {
                name: "Basic 4 Week",
                price: 4000,
                description: "A comprehensive four-week boarding and training program that includes of two individual sessions and three group classes.",
                url: ""
            },
            {
                name: "Basic 6 Week",
                price: 5700,
                description: "A comprehensive six-week boarding and training program that includes of two individual sessions and three group classes.",
                url: ""
            },
            {
                name: "Premium 3 Week",
                price: 4150,
                description: "A comprehensive three-week boarding and training program that includes of seven individual sessions and six group classes.",
                url: ""
            },
            {
                name: "Premium 4 Week",
                price: 4700,
                description: "A comprehensive four-week boarding and training program that includes of seven individual sessions and six group classes.",
                url: ""
            },
            {
                name: "Premium 6 Week",
                price: 6400,
                description: "A comprehensive six-week boarding and training program that includes of seven individual sessions and six group classes.",
                url: ""
            }
        ]
        
    }
}
export default servicesMap