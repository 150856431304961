import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';

import { ServicesCardContainer, ServicesCardRow, ServicesCardImg } from './service-card.styles'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

const ServiceCard = ({title, description, dogImg, url}) =>{
    return (
        <ServicesCardContainer>
            <ServicesCardRow>
                <Col xs={2}>
                    <ServicesCardImg src={dogImg}/>
                </Col>
                <Col xs={10}>
                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>
                            {description}
                        </Card.Text>
                        <Button className='custom-button-primary' href={url}>More Info...</Button>
                    </Card.Body>
                </Col>
            </ServicesCardRow>
        </ServicesCardContainer>
    )
}

export default ServiceCard