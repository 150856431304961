import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Shop from './routes/shop/shop.component';
import About from './routes/about/about.component';
import Landing from "./routes/landing/landing.component";
import Services from './routes/services/services.component';
import CheckoutPage from './routes/checkout/checkout.component';
import Navigation from './routes/Navigation/navigation.component';
import Service from './routes/services/route/service/service.compnent';
import UpdateProfile from './routes/update_profile/update_profile.route';
import SignInPage from './routes/sign-up/sign-up.route';
import Profile from './routes/profile/profile.route';

import { getAPI } from './utils/api/api';

import { setCurrentUser } from './store/user/user.reducer';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

const App = () => {
  const dispatch = useDispatch();

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await getAPI("/clients/ec");
        const data = response.data;
        // const user = {
        //   name: data.name,
        //   email: data.email,
        //   phone: data.phone,
        //   address: data.address,
        //   dogs: data.dogs,
        //   profilePicture:data.picture,

        // }
        dispatch(setCurrentUser(data))
      } catch (error) {
        console.log("ERROR", error);
      }
    };
  
    fetchData();
  },[])

  return (
    <Routes>
      <Route path='/' element={<Navigation bgTop="transparent" bgScroll="light"/>}>
        <Route index element={<Landing/>}/>
        <Route path='shop/*' element={<Shop/>}/>
      </Route>
      <Route path='/about' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<About/>}/>
      </Route>
      <Route path='/Services/*' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<Services/>}/>
        <Route path=":service" element={<Service/>}/>
      </Route>
      <Route path='/payment' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<CheckoutPage/>}/>
      </Route>
      <Route path='/updateprofile' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<UpdateProfile/>}/>
      </Route>
      <Route path='/signin' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<SignInPage/>}/>
      </Route>
      <Route path='/profile' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<Profile/>}/>
      </Route>
    </Routes>
  );
};

export default App;
