import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

const SignInPage = () => {
  const handleSignUp = () => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.empowercanine.com";
    window.location.href = `${apiUrl}/auth/google`;
  };

  return (
    <Container className="mt-5" style={{padding: "100px 50px"}}>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h1>Sign In / Sign Up</h1>
          <p>Sign in using your Google account:</p>
          <Button variant="danger" onClick={handleSignUp}>
            Sign Up with Google
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInPage;
