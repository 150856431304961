import Button from "react-bootstrap/Button"
import styled from "styled-components";
import Card from "react-bootstrap/Card"

export const PriceCardContainer = styled(Card)`
    // max-width: 14rem;
    background-color: white;

    .card-subtitle, .card-title{
        text-align: center;
    }

    .card-subtitle{
        font-size: 3rem;
    }
`

export const PriceButton = styled(Button)`
    
`

