import { useParams } from 'react-router-dom'

import PricingSection from '../../components/pricing/pricing.component';

import { ServiceContainer, ServiceCol, ServiceRow, DescriptionSection, ParagraphContainer } from './service.styles';

import servciesMap from "../../data/services.data" 

const Service = () => {
    const { service } = useParams();
    const serviceData = servciesMap[service]
    return(
        <ServiceContainer>
            <div className='titlecontainer'>
                <h1>{serviceData.name}</h1>
                <p>
                    {serviceData.catchphrase}
                </p>
            </div>
            <DescriptionSection>
                <ParagraphContainer>
                    <p style={{color:"black"}}>
                        At Empower Canine, our mission is to enhance the relationship between dog owners and their beloved companions through balanced training methods. We educate owners on effective techniques, fostering understanding and cooperation. With NePoPo, our responsive dog training system, we ensure dogs perform with heart and soul. We provide a supportive environment, helping owners overcome challenges and strengthen their bond. Through inclusive community engagement, programs, workshops, and personalized sessions, we empower owners to navigate any situation with confidence. By promoting clear communication, trust, and understanding, we create lifelong partnerships between humans and their furry friends.
                    </p>
                </ParagraphContainer>
            </DescriptionSection>
            <PricingSection prices={serviceData.prices}/>
            
        </ServiceContainer>
    )
}

export default Service;