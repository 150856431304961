import { PricingSectionDiv, DarkH3, PricingRow, PricingCol,PricingTitle } from './pricing.style';

import img1 from "../../../../assets/services-section-dogs/services-dog-1.jpg"
import img2 from "../../../../assets/services-section-dogs/services-dog-2.jpg"
import img3 from "../../../../assets/services-section-dogs/services-dog-3.jpg"
import img4 from "../../../../assets/services-section-dogs/services-dog-4.jpg"

import PriceCard from '../pricing-card/pricing-card.component';

const PricingSection = ({prices}) => {
    const getPrices = (prices) => {
        for (const item of prices){

        }
    }
    return(
      <PricingSectionDiv className="py-15 py-xl-20 overflow-hidden bg-light">
          <PricingTitle className="row justify-content-between">
                <DarkH3>Services</DarkH3>
          </PricingTitle>
          <PricingRow className="row justify-content-between">
            {
                prices.map(ele =>
                    <PricingCol sm={6} md={4}>
                    <PriceCard
                    title={ele.name}
                    price={ele.price}
                    description={ele.description}
                    url={ele.url}
                    />
                    </PricingCol>    
                )
            }
          </PricingRow>
      </PricingSectionDiv>
    )
};

export default PricingSection;