import Card from "react-bootstrap/Card"
import Button from 'react-bootstrap/Button';

import MeetTheTrainerSection from "../landing/components/services-section/services-section.component";

import { ServicesContainer, ServicesCol, ServicesRow } from './services.styles'

const Services = () => {
    return (
        <ServicesContainer>
            <MeetTheTrainerSection/>
        </ServicesContainer>
    )
}

export default Services;