import React from 'react';
import { format } from 'date-fns';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card'
import PetsIcon from '@mui/icons-material/Pets';
import PostCard from '../post-card/post-card.component';
import { PorfilePictureContainer } from './dog-profile.styles';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../store/user/user.selector';

const DogProfile = ({ dog, handleEditDog, hide }) => {
  const { name, profilePicture, breed, gender, birthday, profilePicturePosition } = dog;
  const user = useSelector(selectCurrentUser)
  console.log(user.posts)
  const posts = user.posts.filter((post)=>post.dog == dog._id)

  console.log(dog)
  const formattedBirthday = format(new Date(birthday), 'MMMM d, yyyy');
  
  return (
    <div>
      <Row className="justify-content-center align-items-center">
        <Col>
          <a onClick={hide}>
           <PetsIcon style={{fontSize:"3rem", color:"white"}}/>
          </a>
        </Col>
        <Col className='text-center'>
            <h2 style={{color:"white", fontSize:"2rem", fontWeight:"bold", textTransform:"capitalize"
                , letterSpacing:"0.1rem"
                , textShadow:"0px 0px 5px #000000"
                , textDecorationSkipInk:"none"}}>{name}
              </h2>
            <PorfilePictureContainer>
              <PorfilePictureContainer.Image
                  src={profilePicture}
                  alt="Dog Profile"
                  roundedCircle 
                  className="mx-auto d-block"
                  style={{
                    objectPosition: `${profilePicturePosition.x}% ${profilePicturePosition.y}%`,
                  }}
              />
            </PorfilePictureContainer>
            
            <br/>
            <a style={{fontSize: '1.5rem', color:"white", cursor:"pointer"}} onClick={handleEditDog}><u>edit</u></a>
        </Col>
        <Col>
          {/* <PetsIcon style={{fontSize:"3rem", color:"white"}}/> */}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <span>Breed: {breed}</span>
        </Col>
        <Col>|</Col>
        <Col>
          <span>Gender: {gender}</span>
        </Col>
        <Col>|</Col>
        <Col>
          <span>Birthday: {formattedBirthday}</span>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col style={{display: "flex", flexDirection: "column", alignItems:"center"}}>
          <h4>Notes</h4>
          { posts && posts.map((post, index) => (
            <>
              <PostCard key={index} post={post} />
              <br/>
            </>
            
            // <Card key={index} className="mt-3">
            //   <Card.Body>{post.message}</Card.Body>
            // </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default DogProfile;
