import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'

export const ServicesCardContainer = styled(Card)`
    // padding: 25px;
    
`

export const ServicesCardRow = styled(Row)`
    padding-left: calc(var(--bs-gutter-x) * .5);
`

export const ServicesCardImg= styled(Card.Img)`
    position: relative;
    max-height: 75%;
    top: 20%;
`