import Image from 'react-bootstrap/Image'
import CloseButton from 'react-bootstrap/CloseButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { InfoBarHeaderDiv, InfoBarHeaderAvatar, InfoBarHeaderCloseButtonContainer } from './info-bar-header.styles';

const InfoBarHeader = (props) =>{
    const { user } = props
    return (
        <InfoBarHeaderDiv>
            <InfoBarHeaderCloseButtonContainer onClick={props.hide}>
                <CloseButton onClick={props.hide} variant="white"/>
            </InfoBarHeaderCloseButtonContainer>
            <InfoBarHeaderAvatar>
                <Image roundedCircle src={user.profilePicture}></Image>
            </InfoBarHeaderAvatar>
            <div className="name">
                <h5>{user.name}</h5>
            </div>
            <div className="description">
                <small>{user.email}</small>
                <br/>
                <small>{user.phone}</small>
                <br/>
                <a href='/updateprofile'><small><u>Edit</u></small></a>
            </div>
            <Row>
            <Col xs={4} style={{textAlign: "center"}}>
                    <small >{user.groupClasses}</small>
                    <br/>
                    <small>Group</small>
                </Col>
                <Col xs={4} style={{textAlign: "center"}}>
                    <small >{user.bennettSessions}</small>
                    <br/>
                    <small>Private</small>
                </Col>
                <Col xs={4} style={{textAlign: "center"}}>
                    <small>{user.privateSessions}</small>
                    <br/>
                    <small>Bennett</small>
                </Col>
            </Row>
        </InfoBarHeaderDiv>
    )
}

export default InfoBarHeader