import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

// if creator not in mem (profile component) api request it and add it to mem and if in mem pull that data
const PostCard = ({ post }) => {
  const { creator, createdAt, media } = post;

  return (
    <Card style={{width: "80%", textAlign:"left", backgroundColor: "rgb(43,58,81)", color:"white"}}>

        <Card.Body>
        <Row>
            {
                creator.picture && 
                <Col sm={3}>
                    <Card.Img variant="top" src={creator.picture} alt="Creator" />
                </Col>
            }
            
            <Col sm={9}></Col>
                <Card.Title>
                    {creator}
                    <br/>
                    {new Date(createdAt).toDateString()}
                </Card.Title>
        </Row>
        <Row>
            <Card.Text>{post.message}</Card.Text>
        </Row>
        { media &&
            <>
                <br/>
                <Row>
                   <Card.Img variant="bottom" src={media} alt="Post" />
                </Row>
            </>
        }
        </Card.Body>      
    </Card>
  );
};

export default PostCard;
