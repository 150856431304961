import styled from "styled-components";

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container'

export const UpdateProfileContainer = styled(Container)`
    padding: 130px 50px;
`

export const UpdateProfileForm = styled(Form)`

`