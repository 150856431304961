
import { DogCardComponent, DogCol, DogRow, DogImgContainer, BackgroundBlur } from "./info-bar-dog.styles";

//FIX FOLLOW OSCARSHUB NAVBAR 
const DogCard = ({dog}) =>{

    return (
        <DogCardComponent>
            <BackgroundBlur  dog={dog}/>
            <DogCardComponent.Body>
                <DogRow>
                    <DogCol xs={6}>
                        <DogImgContainer>
                            <DogCardComponent.Img src={dog.profilePicture} style={{
                                objectPosition: `${dog.profilePicturePosition.x}% ${dog.profilePicturePosition.y}%`,
                            }}/>
                        </DogImgContainer>
                    </DogCol >
                    <DogCol xs={6} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <h3>{dog.name}</h3>
                    </DogCol >
                </DogRow>
            </DogCardComponent.Body>
        </DogCardComponent>
    );
};

export default DogCard;