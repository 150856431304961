import axios from 'axios';

const API = axios.create({
    withCredentials: true,
  });

const API_URL = process.env.REACT_APP_API_URL || "https://api.empowercanine.com";
// const API_URL = process.env.REACT_APP_API_URL || "http://192.168.86.44:8000";


export const getAPI = async (path, body) => {
    const response = await API.get(`${API_URL}${path}`, JSON.stringify(body),{
        headers: {
            'Content-Type': "application/json"
        }
    })
    return await response
}

export const postAPI = async (path, body) => {
    const response = await API.post(`${API_URL}${path}`,JSON.stringify(body),{
        headers: {
            'Content-Type': "application/json"
        }
    })
    return await response
}

export const puttAPIMultipart = async (path, body) => {
    const response = await API.put(`${API_URL}${path}`, body, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }})
    return await response
}