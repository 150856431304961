import InfoBarHeader from '../info-bar-header/info-bar-header.component';
import InfoBarContent from '../info-bar-content/info-bar-content.component';
import AddDog from '../info-bar-add-dog/info-bar-add-dog.component';

import { InfoBarStack } from './info-bar.styles';


const InfoBar = (props) =>{

    return (
        <InfoBarStack gap={3}>
            
            {
                props.user ? <>
                    <InfoBarHeader hide={props.hide} user={props.user}/>
                    <InfoBarContent dogs= {props.dogs} selectDog={props.selectDog} hide={props.hide}/>
                    <AddDog handleAddDog={props.handleAddDog}
                    />
                </> : <></>
            }
            
        </InfoBarStack>
    )

}

export default InfoBar