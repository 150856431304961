import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";

export const PricingSectionDiv = styled.section`
    padding: 4rem;
    
`

export const DarkH3 = styled.h3`

`

export const PricingTitle = styled(Row)`
    text-align: center;
`

export const PricingRow = styled(Row)`
`

export const PricingCol = styled(Col)`
    height: 100%;
    padding-top: 50px;
`