import styled from 'styled-components';

import BackgroundPic from '../../../../assets/Goose2.jpg'

export const HeroSectionContainer = styled.section`
    background-image: url(${BackgroundPic});
    transform: scale(1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 40%);

    @media only screen and (max-width: 992px){
        padding-top: 20px;
        padding-bottom: 2rem
    }
`