import Figure from 'react-bootstrap/Figure';

import HectorPic from '../../../../assets/users/Portrait 2.jpg'
import GusPic from '../../../../assets/users/Goose Portrait 2.jpg'
import PepitaPic from '../../../../assets/users/Goose Portrait 2.jpg'

import { MeetTheTrainerSectionDiv, DarkH3, MeetTheTrainerRow, MeetTheTrainerCol, PortraitFigure } from './meet-the-tainers-section.styles';

const MeetTheTrainerSection = () => {
    return(
      <MeetTheTrainerSectionDiv className="py-15 py-xl-20 overflow-hidden bg-dark">
          <MeetTheTrainerRow className="row justify-content-between">
                <DarkH3>Meet Your Trainers</DarkH3>
          </MeetTheTrainerRow>
          <MeetTheTrainerRow className="row justify-content-between" xs={400}>
            <MeetTheTrainerCol>
                <PortraitFigure>
                    <Figure.Image
                        width={300}
                        height={330}
                        alt="171x180"
                        src={HectorPic}
                    />
                    <Figure.Caption>
                        Hector 
                    </Figure.Caption>
                </PortraitFigure>
            </MeetTheTrainerCol>
          </MeetTheTrainerRow>
          <MeetTheTrainerRow className="row justify-content-between">
            <MeetTheTrainerCol sm={6}>
                <PortraitFigure>
                    <Figure.Image
                        width={300}
                        height={330}
                        alt="171x180"
                        src={GusPic}
                    />
                    <Figure.Caption>
                        Goose 
                    </Figure.Caption>
                </PortraitFigure>
            </MeetTheTrainerCol>
            <MeetTheTrainerCol sm={6}>
                <PortraitFigure>
                    <Figure.Image
                        width={300}
                        height={330}
                        alt="171x180"
                        src={PepitaPic}
                    />
                    <Figure.Caption>
                        Pepita 
                    </Figure.Caption>
                </PortraitFigure>
            </MeetTheTrainerCol>
          </MeetTheTrainerRow>
      </MeetTheTrainerSectionDiv>
    )
};

export default MeetTheTrainerSection;