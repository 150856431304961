import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Botton from 'react-bootstrap/Button';

//FIX FOLLOW OSCARSHUB NAVBAR 
const AddDog = ({ handleAddDog }) =>{

    return (
        <Container style={{padding: "1rem"}}>
            <Row>
                <Col>
                    <Botton size='lg' style={{width:"100%"}} className='custom-button-primary' onClick={handleAddDog}>Add Dog</Botton>
                </Col>
            </Row>
        </Container>
    );
};

export default AddDog;