import { ServicesSectionDiv, DarkH3, ServicesRow, ServicesCol,ServicesTitle } from './services-section.styles';

import img1 from "../../../../assets/services-section-dogs/services-dog-1.jpg"
import img2 from "../../../../assets/services-section-dogs/services-dog-2.jpg"
import img3 from "../../../../assets/services-section-dogs/services-dog-3.jpg"
import img4 from "../../../../assets/services-section-dogs/services-dog-4.jpg"

import ServiceCard from "../../../../components/service-card/service-card.component"

const MeetTheTrainerSection = () => {
    return(
      <ServicesSectionDiv className="py-15 py-xl-20 overflow-hidden bg-light">
          <ServicesTitle className="row justify-content-between">
                <DarkH3>Services</DarkH3>
          </ServicesTitle>
          <ServicesRow className="row justify-content-between">
            <ServicesCol sm={4}>
              <ServiceCard
                title="Board and Train"
                description="10-week program for dogs involves intensive training and behavior modification while the dog stays at a training facility. Trainers work on basic obedience, address behavioral issues, socialize the dog, and provide regular exercise and mental stimulation"
                dogImg={img1}
                url={"/services/boardandtraining"}
                />
                
            </ServicesCol>
            <ServicesCol sm={4}>
              <ServiceCard 
                title={"Private Lessons"}
                description={"Private dog training provides personalized one-on-one sessions between a professional trainer and a dog owner. It addresses specific training needs and behavioral issues with tailored techniques and guidance."}
                dogImg={img3}
                url={"/services/privateclass"}
              />
            </ServicesCol>
            <ServicesCol sm={4}>
              <ServiceCard
                title={"Group Class"}
                description={"Puppies can be a handful. It’s vital that you have a plan for how you are going to raise them. We have multiple training options that will suit any situation and leave you feeling confident and on track."}
                dogImg={img2}
                url={"/services/groupclass"}
              />
            </ServicesCol>
          </ServicesRow>
      </ServicesSectionDiv>
    )
};

export default MeetTheTrainerSection;