import styled from 'styled-components';

export const AboutContainer = styled.div`
    padding: 20px;
    text-align: center;


    @media only screen and (min-width: 992px){
        padding-top: 120px;
    }
`

export const ParagraphContainer = styled.div`
    max-width: 500px;
    display: inline-block;
    position: relative;
    color: aliceblue;
`
