import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { format } from 'date-fns';
import ImageUploadWithObjectPosition from '../image-uploader/image-uploader.component';

const DogModal = ({ show, handleClose, onSubmit, dog }) => {
  const [formData, setFormData] = useState({
    name: dog ? dog.name : '',
    breed: dog ? dog.breed : '',
    gender: dog ? dog.gender : 'Male',
    birthday: dog ? format(new Date(dog.birthday), 'yyyy-MM-dd') : '',
    sterilized: dog ? dog.sterilized : false,
    vaccines: dog ? dog.vaccines : '',
    profilePicture: dog ? dog.profilePicture : '',
    dogPicturePath: dog && dog.dogPicturePath ? dog.dogPicturePath : '',
    oldProfilePicture: dog ? dog.profilePicture : '',
    updated: dog ? dog.updated : Date.now(),
    profilePicturePosition: dog ? dog.profilePicturePosition : {x:0, y:0}
  });

  useEffect(()=>{
    if (dog){
        setFormData(dog)
    }
},[dog])

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = () => {
    setFormData({ ...formData, updated: Date.now() })
    onSubmit(formData, dog);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{dog ? 'Edit Dog' : 'Add Dog'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageUploadWithObjectPosition handlechange={handleChange} setFormData={setFormData} formData={formData}/>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="breed">
            <Form.Label>Breed</Form.Label>
            <Form.Control
              type="text"
              name="breed"
              value={formData.breed}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="gender">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <option>Male</option>
              <option>Female</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="birthday">
            <Form.Label>Birthday</Form.Label>
            <Form.Control
              type="date"
              name="birthday"
              value={formData.birthday}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="sterilized">
            <Form.Check
              type="checkbox"
              label="Sterilized"
              name="sterilized"
              checked={formData.sterilized}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="vaccines">
            <Form.Label>Vaccines</Form.Label>
            <Form.Control
              type="text"
              name="vaccines"
              value={formData.vaccines}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group controlId="profilePicture">
            <Form.Label>Profile Picture</Form.Label>
            <Form.Control
              type="file"
              name="profilePicture"
              onChange={e => { setFormData({ ...formData, profilePicture: e.target.files[0], dogPicturePath: e.target.value });}}
            />
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {dog ? 'Save Changes' : 'Add Dog'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DogModal;
