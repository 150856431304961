import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";

export const ServicesSectionDiv = styled.section`
    padding: 2rem;
`

export const DarkH3 = styled.h3`

`

export const ServicesTitle = styled(Row)`
    text-align: center;
`

export const ServicesRow = styled(Row)`
`

export const ServicesCol = styled(Col)`
    height: 100%;
    padding-top: 50px;
`