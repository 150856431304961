import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import Figure from 'react-bootstrap/Figure';

export const MeetTheTrainerSectionDiv = styled.section`
    padding: 20px;
`

export const DarkH3 = styled.h3`
    color: white;
`

export const MeetTheTrainerRow = styled(Row)`
    text-align: center;
    height: 100%;
`

export const MeetTheTrainerCol = styled(Col)`
    height: 100%;
`

export const PortraitFigure = styled(Figure)`
    figcaption{
        font-size: 35px;
        color: white;
        position: relative;
        // bottom: 46px;
        // font-family: cursive;
    }
`