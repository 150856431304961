import { AboutContainer, ParagraphContainer } from "./about.styles";

const About = ( {} ) => {

    return (
        <AboutContainer> 
            <ParagraphContainer>
            <h3>Mission Statement</h3>
                <p>
                    At Empower Canine, our mission is to enhance the relationship between dog owners and their beloved companions through balanced training methods. We educate owners on effective techniques, fostering understanding and cooperation. With NePoPo, our responsive dog training system, we ensure dogs perform with heart and soul. We provide a supportive environment, helping owners overcome challenges and strengthen their bond. Through inclusive community engagement, programs, workshops, and personalized sessions, we empower owners to navigate any situation with confidence. By promoting clear communication, trust, and understanding, we create lifelong partnerships between humans and their furry friends.
                </p>
            </ParagraphContainer>
        </AboutContainer>
    )
}

export default About;