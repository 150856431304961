import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import styled from 'styled-components';

export const CheckoutContainer = styled.div`
    height: 100vh;
    

    @media only screen and (min-width: 992px){
        padding-top: 100px;
        overflow: hidden;
    }
`

export const CheckoutRow = styled(Row)`
    height: 100%;
    padding-left: unset;
    padding-right: unset;
`

export const CheckoutCol = styled(Col)`
    // padding: 20px 50px;
`
export const DescriptionContainer = styled.div`
  background-color: #E7ECEE;
  padding: 20px 50px;
  height: 100%;
  color: black;
`;

export const FormContainer = styled.div`
  background-color: white;
  padding: 20px 50px;
  height: 100%;
`;