import styled from "styled-components";
// import Container from 'react-bootstrap/Container';
import Figure from "react-bootstrap/Figure";

// const DogProfileContainer = styled(Container)`
    
// `

export const PorfilePictureContainer = styled(Figure)`
    width: 13rem; /* Adjust the width as needed */
    height: 13rem; /* Adjust the height as needed */
    position: relative;
    overflow: hidden;

    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`