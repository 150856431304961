import { Outlet } from "react-router-dom";

import HeroSection from "./components/hero-section/hero-section.component";
import NewClientForm from "./components/new-client-form/new-client-form.component";
import ServicesSection from "./components/services-section/services-section.component";
import FeaturedCommentSection from "./components/featured-comments/featured-comments.component";
import HowtostartSection from "./components/how-to-start-section/how-to-start-section.component";
import MeetTheTrainerSection from "./components/meet-the-trainers-section/meet-the-trainers-section.component";


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './landing.styles.scss'

const Landing = () => {
  return (
    <>
      <HeroSection/>
      <MeetTheTrainerSection/>
      <ServicesSection/>
      {/* <FeaturedCommentSection/>
      <HowtostartSection/> */}
      {/* Contact Info??? */}
      
    </>

  );
};

export default Landing;
