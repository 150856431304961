

import { PriceCardContainer, PriceButton } from "./pricing-card.style";

const PriceCard = (props) => {
    const { title, description, price, url } = props
    return(
        <PriceCardContainer>
            <PriceCardContainer.Body>
                <PriceCardContainer.Title>
                    {title}
                </PriceCardContainer.Title>
                <PriceCardContainer.Subtitle>
                    ${price}
                </PriceCardContainer.Subtitle>
                <PriceCardContainer.Text>
                    {description} (Non Refundable)
                </PriceCardContainer.Text>
                <div className="d-grid gap-2">
                    {/* PREFILL EMAILS */}
                    {/* https://buy.stripe.com/test_eVa3do41l4Ye6KkcMN?prefilled_email=jenny%40example.com */}
                    <PriceButton href="https://buy.stripe.com/test_28o02m2om0P3d0c3cd" size="lg" disabled>Get</PriceButton>
                </div>
            </PriceCardContainer.Body>
        </PriceCardContainer>
    )
} 

export default PriceCard;