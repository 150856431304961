import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";

import { puttAPIMultipart } from '../../utils/api/api';

import InfoBar from "./components/info-bar/info-bar.component";
import DogProfile from "./components/dog-profile/dog-profile.component";
import DogModal from './components/dog-modal/dog-modal.component';

import { selectCurrentUser } from '../../store/user/user.selector';

import { MainContainer, MainRow, InfoBarContainer, ContentContainer, ContentRow, ContentFooter } from './profile.styles'


const Profile = () => {
    const user = useSelector(selectCurrentUser)
    const [showAddDog, setShowAddDog] = useState(false);
    const [showEditDog, setShowEditDog] = useState(false);
    const [dog,setDog] = useState(null)
    const [dogs,setDogs] = useState(null)
    const [isHidden, setIsHidden] = useState(false)

    const handleAddDog = () => {
        setShowAddDog(true);
      };

    const handleEditDog = () => {
        setShowEditDog(true);
    };
    
    const handleCloseModal = () => {
        setShowAddDog(false);
        setShowEditDog(false);
    };

    useEffect(()=>{
        if (user){
            setDog(user.dogs[0])
            setDogs(user.dogs)
        }
    },[user])

    const onHideEvent = () => {
        setIsHidden(!isHidden)
    }

    const handleSaveDog = async (formData, dog) => {
        let updatedDogs = dogs;
        if (dog){
            updatedDogs = dogs.map((dog) => (dog._id === formData._id ? formData : dog));
        }
        else{
            updatedDogs = [formData, ...dogs];
        }
        const updatedUser = {...user};

        const updatedFormData = new FormData();
        updatedFormData.append('user', JSON.stringify(updatedUser));
        updatedDogs.forEach((dog, index) => {
            const dogJSON = JSON.stringify(dog);
            updatedFormData.append(`dogs`, dogJSON);
            if (dog.dogPicturePath){
                updatedFormData.append(`dogs`, dog.profilePicture, `${dog.name}.jpg`);
            }
        });
        try{
            const res = await puttAPIMultipart(`/clients/${user.gid}`,updatedFormData)
            setDogs(res.data.dogs)
            if (res.status === 200){
                if (!dog)
                    // refresh
                    setDog(res.data.dogs[0])
                else{
                    setDog(res.data.dogs.find(dogObj => dogObj._id === dog._id))
                }
            }
        }
        catch(err){
        console.log(err)
        }
      };

    return (
        <MainContainer>
        <MainRow>           
            <InfoBarContainer lg={3} xs={0} id="InfoBar" hide={isHidden.toString()}>
                    <InfoBar hide={onHideEvent} user={user} selectDog={setDog} dogs={dogs} handleAddDog={handleAddDog}/>
            </InfoBarContainer>               
            <ContentContainer lg={9} xs={12} hide={isHidden.toString()} className="ContentContainer">
                <ContentRow id="home">
                        {
                            dog ? <DogProfile dog={dog} handleEditDog={handleEditDog} hide={onHideEvent}/> : <></>
                        }
                </ContentRow>  
                {/* <Footer/>                               */}
            </ContentContainer>
            <DogModal
                show={showAddDog}
                handleClose={handleCloseModal}
                onSubmit={handleSaveDog}
            />
            <DogModal
                show={showEditDog}
                handleClose={handleCloseModal}
                onSubmit={handleSaveDog}
                dog={dog}
            />
        </MainRow>
    </MainContainer>
    );
}; 

export default Profile;