import { Outlet, Link } from "react-router-dom";
import { Fragment, useState, useEffect,useContext } from "react";
import { useSelector } from 'react-redux';

// import { CartContext } from "../../contexts/cart.context";
import { ReactComponent as  EmpowerLogo} from "../../assets/image2vector.svg";

// import CartIcon from "../../old-components/cart-icon/cart-icon.component";
// import CartDropdown from "../../old-components/cart-dropdown/cart-dropdown.component";

import { selectCurrentUser } from '../../store/user/user.selector';

import { NavigationBar, LeftNavigationNav, RightNavigationNav } from "./navigation.styles.jsx"
import { getAPI } from "../../utils/api/api";

const Navigation = (props) => {
  const currentUser = useSelector(selectCurrentUser);
  // const { isCartOpen } = useContext(CartContext)
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const atTop = currentScrollPos <= 10;
      setScrolled(prevScrollpos > currentScrollPos && !atTop);
      setVisible(prevScrollpos > currentScrollPos || atTop)
      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos]);

  const logout = async () => {
    console.log("logging out")
    console.log(currentUser)
    try{
      await getAPI("/auth/logout");
      window.location.href = "/"
    }
    catch(err){
      console.log(err)
    }
  }

    return (
      <Fragment>
        <NavigationBar bg={scrolled || window.innerWidth < 992 ? props.bgScroll : props.bgTop} expand="lg" fixed="top" className={!visible ? 'navbar-hidden' : ''}>
            <NavigationBar.Brand>
              <Link to="/">
                <EmpowerLogo width="300" height="80" alt="Logo"/>
              </Link>
            </NavigationBar.Brand>
            <NavigationBar.Toggle aria-controls="navbar-nav" />
            <NavigationBar.Collapse id="navbar-nav" >
              <LeftNavigationNav className="me-auto">
                <LeftNavigationNav.Link href="/about">About</LeftNavigationNav.Link>
                <LeftNavigationNav.Link href="/services">Services</LeftNavigationNav.Link>
                {/* <LeftNavigationNav.Link href="/shop">Shop</LeftNavigationNav.Link> */}
              </LeftNavigationNav>

              <RightNavigationNav>
                {currentUser ? (
                  <>
                    <RightNavigationNav.Link href="/profile">Profile</RightNavigationNav.Link>
                    {/* <RightNavigationNav.Link href="/payment">Checkout</RightNavigationNav.Link> */}
                    <RightNavigationNav.Link onClick={logout}>Sign Out</RightNavigationNav.Link>
                    
                  </>
                    
                  ) : (
                    <RightNavigationNav.Link href="/signin">Sign In</RightNavigationNav.Link>
                )}
                
                {/* <CartIcon /> */}
              </RightNavigationNav>
              
              
            </NavigationBar.Collapse>
            {/* {isCartOpen && <CartDropdown />} */}
        </NavigationBar>
        {
          window.innerWidth < 992 ?
          <div style={{width: "100%", height: "106px", backgroundColor: "rgb(33,37,41)"}}></div>
          : <></> 
        }
        <Outlet/>
      </Fragment>
    );
  }

export default Navigation