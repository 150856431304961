import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export const NavigationBar = styled(Navbar)`
  transition: top 0.3s ease;
  color: white;
  padding-right: 1rem;

  &.navbar-hidden {
    top: -100px;
  }

  &.bg-light{
    background-color: #7D828E !important;
  }

  .navbar-collapse{
    padding-left: 1rem;
  }

`

export const LeftNavigationNav = styled(Nav)`
  a{
    color: #E7ECEE;
  }
`
export const RightNavigationNav = styled(Nav)`
  padding-right: 30px;
  a{
    color: #E7ECEE;
  }
`
