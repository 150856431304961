import { Routes, Route,Outlet } from 'react-router-dom';

const Shop = () => {

    return (
        <Routes>
            {/* <Route index element={<CategoriesPreview/>}/> */}
            {/* <Route path=':category' element={<Category/>}/> */}
        </Routes>
    )
}

export default Shop;