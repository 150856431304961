import { useSelector  } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { puttAPIMultipart } from '../../utils/api/api';

import { Button, Row, Col, Form } from 'react-bootstrap';

import { UpdateProfileContainer, UpdateProfileForm } from './update_profile.styles';

import { selectCurrentUser } from '../../store/user/user.selector';

const UpdateProfile = () => {
  const user = useSelector(selectCurrentUser);
  const [phone, setPhone] = useState(''); 
  const [address, setAddress] = useState('');

  useEffect(()=>{
    if (user) {
      setPhone(user.phone);
      setAddress(user.address);
    }
  },[user])
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedUser = {
      ...user,
      phone: phone,
      address: address,
    }
    console.log(updatedUser)
    const formData = new FormData();
    formData.append('user', JSON.stringify(updatedUser));
    user.dogs.forEach((dog, index) => {
      const dogJSON = JSON.stringify(dog);
      formData.append(`dogs`, dogJSON);
  });
    try{
      console.log("CALLING API")
      const res = await puttAPIMultipart(`/clients/${user.gid}`,formData)
      console.log(res)
      if (res.status === 200){
        // window.location.reload();
      }
      
    }
    catch(err){
      console.log(err)
    }
  };

  return (
    <UpdateProfileContainer>
      <Row>
        <h1>Update Profile</h1>
        <Col>
          <UpdateProfileForm onSubmit={handleSubmit}>
            <UpdateProfileForm.Group controlId="name">
              <UpdateProfileForm.Label>Name</UpdateProfileForm.Label>
              <UpdateProfileForm.Control type="text" placeholder="name" value={user ? user.name : ''} disabled/>
            </UpdateProfileForm.Group>

            <UpdateProfileForm.Group controlId="phone">
              <UpdateProfileForm.Label>Phone</UpdateProfileForm.Label>
              <UpdateProfileForm.Control type="text" placeholder="Enter phone number" value={phone} onChange={e => setPhone(e.target.value)}/>
            </UpdateProfileForm.Group>

            <UpdateProfileForm.Group controlId="homeAddress">
              <UpdateProfileForm.Label>Home Address</UpdateProfileForm.Label>
              <UpdateProfileForm.Control type="text" placeholder="Enter home address" value={address} onChange={e => setAddress(e.target.value)}/>
            </UpdateProfileForm.Group>

            <UpdateProfileForm.Group controlId="email">
              <UpdateProfileForm.Label>Email</UpdateProfileForm.Label>
              <UpdateProfileForm.Control
                type="text"
                placeholder="Email"
                value={user ? user.email : ''}
                disabled
              />
            </UpdateProfileForm.Group>
            <br/>
            <Button type="submit" size='lg' className='custom-button-primary'>
              Save Profile
            </Button>
          </UpdateProfileForm>
        </Col>
      </Row>
    </UpdateProfileContainer>
  );
};

export default UpdateProfile;
