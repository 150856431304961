import React, { useRef, useState } from 'react';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { PorfilePictureContainer } from '../dog-profile/dog-profile.styles';

const DraggableImage = ({ src, objectPosition, onDrag }) => {
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const offsetXRef = useRef(0);
  const offsetYRef = useRef(0);

  const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
  };

  const handleMouseDown = (e) => {
    // e.preventDefault();
    startDragging(e.clientX, e.clientY);
  };

  const handleTouchStart = (e) => {
    // e.preventDefault();
    const touch = e.touches[0];
    startDragging(touch.clientX, touch.clientY);
  };

  const startDragging = (clientX, clientY) => {
    setIsDragging(true);
    offsetXRef.current = clientX;
    offsetYRef.current = clientY;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    const deltaX = e.clientX - offsetXRef.current;
    const deltaY = e.clientY - offsetYRef.current;

    const newX = clamp(
      objectPosition.x + (deltaX / containerRect.width) * 100,
      0,
      100
    );
    const newY = clamp(
      objectPosition.y + (deltaY / containerRect.height) * 100,
      0,
      100
    );

    onDrag(newX, newY);
    offsetXRef.current = e.clientX;
    offsetYRef.current = e.clientY;
  };

  const handleTouchMove = (e) => {
    if (!isDragging || e.touches.length !== 1) return;
    const touch = e.touches[0];
    const containerRect = containerRef.current.getBoundingClientRect();
    const deltaX = touch.clientX - offsetXRef.current;
    const deltaY = touch.clientY - offsetYRef.current;

    const newX = clamp(
      objectPosition.x + (deltaX / containerRect.width) * 100,
      0,
      100
    );
    const newY = clamp(
      objectPosition.y + (deltaY / containerRect.height) * 100,
      0,
      100
    );

    onDrag(newX, newY);
    offsetXRef.current = touch.clientX;
    offsetYRef.current = touch.clientY;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = () => {
    // Reset isDragging when entering the picture container
    if (!isDragging) {
      setIsDragging(false);
    }
  };

  return (
    <PorfilePictureContainer
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ position: 'relative' }}
    >
      <PorfilePictureContainer.Image
        src={src}
        alt="Dog Profile"
        roundedCircle
        className="mx-auto d-block"
        style={{
          objectPosition: `${objectPosition.x}% ${objectPosition.y}%`,
          cursor: isDragging ? 'grabbing' : 'grab',
        }}
        draggable="false"
      />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <OpenWithIcon style={{ fontSize: '5rem' }} /> {/* Adjust the fontSize here */}
      </div>
    </PorfilePictureContainer>
  );
};

export default DraggableImage;
