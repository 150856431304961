import styled from 'styled-components'

import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const DogCardComponent = styled(Card)`
    cursor: pointer;
    color: black;
    z-index: 1;
    outline: #6c757d solid 3px;
    background-color: #2553A0ff;
`

export const BackgroundBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${props => props.dog ? props.dog.profilePicture : ''});
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  z-index: -1;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 20%);
//   objectPosition: ${props => props.dog ? `${props.dog.profilePicturePosition.x}% ${props.dog.profilePicturePosition.y}%` : ''}
`;

export const DogImgContainer = styled.div`
    // width: 100%; 
    height: 5rem; 
    position: relative;
    overflow: hidden;
    z-index: 1;
    img{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`

export const DogRow = styled(Row)`

`
export const DogCol = styled(Col)`
    h3{
        font-weight: 500;
        background: rgb(0 0 0 / 30%);
        color: aliceblue;
    }
`