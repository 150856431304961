
import { InfoBarContentScrollDiv } from './info-bar-content.styles';
import DogCard from '../info-bar-dog/info-bar-dog.component';

const InfoBarContent = ({dogs, selectDog, hide}) =>{
    const handleDogClick = (dog) => {
        selectDog(dog)
        hide();
    }

    return(
        <InfoBarContentScrollDiv>
            {
                dogs ? dogs.map(dog => {
                    return (
                        <div key={dog._id}>
                            <a onClick={()=>handleDogClick(dog)} >
                                <DogCard dog={dog} hide={hide}/>
                            </a>
                            <br/>
                        </div>
                    )
                }) : <></>
            }
        </InfoBarContentScrollDiv>
    )
}

export default InfoBarContent