import styled from 'styled-components'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const MainContainer = styled(Container)`
    padding-top: 121px;
    // padding-bottom: 15px;
    // opacity: 95%;
    // height: calc(100vh);
    
    @media only screen and (max-width: 992px){
        padding-top: 15px;
    }
    @media only screen and (max-width: 576px){
        padding-bottom: 0;
        min-height: 100vh;
        padding-top: 0;
    }
    
`

export const MainRow = styled(Row)`
    background: rgb(62, 76, 98);
    color: black;
    // height: 100%;
    span{
        color: white;
    }
`

export const InfoBarContainer = styled(Col)`
    padding-left: 0; 
    height: 100%;
    transition: 0.5s ease-in-out 0.5s;
    @media only screen and (max-width: 992px){
        ${props => (props.hide === 'true' ? {"display":"none"}: {"display":"contents"} )};
        
    }
`

export const ContentContainer = styled(Col)`
    height: inherit;
    transition: 0.5s ease-in-out 0.5s;
    @media only screen and (max-width: 992px){
        ${props => (props.hide == 'false' ? {"display":"none"}: {"display":"unset", "height":"0"} )};

    }
`

export const ContentRow = styled(Row)`
    padding-top : 12px;
    background: rgb(62,76,98);
`

export const ContentFooter = styled(Row)`
    position: relative;
    width: 98%;
    left: 1%;
    padding: 4px;
    z-index: 1020;
    background: #20202a;
    height: 50px;
`